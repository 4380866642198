import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GetOneEquipment, QueryEquipmentHistory } from "../../actions/ims";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet-async";
import { Input, Select, Table, Tooltip } from "antd";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";

const BLANK_FILTERS = {
  description: null,
  status: null,
  type: null,
  equipmentId: null,
};

const DEFAULT_SIZE = 12;

const EquipmentHistory = () => {
  const { equipmentId } = useParams();

  const [queryLoading, setQueryLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [equipment, setEquipment] = useState({});
  const [customer, setCustomer] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [size, setSize] = useState(DEFAULT_SIZE);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({ ...BLANK_FILTERS, equipmentId: equipmentId });

  let navigate = useNavigate();

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetOneEquipment(equipmentId)
        .then((res) => {
          setEquipment(res.data.equipment);
          setCustomer(res.data.customer);
          setTimeout(() => setLoading(false), 700);
          runHistoryQuery({ ...filters, equipmentId: res.data.equipment.equipmentId }, page, size);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message ?? "Error loading equipment");
          setTimeout(() => navigate("/equipment"), 1800);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const tabs = [
    { name: "Basic Information", href: "#", current: false },
    { name: "Details", href: "details", current: false },
    { name: "Engine", href: "engine", current: false },
    { name: "ATS", href: "ats", current: false },
    { name: "Contact", href: "contact", current: false },
    { name: "Notes", href: "notes", current: false },
    { name: "Photos", href: "photos", current: false },
    { name: "Service Schedule", href: "serviceSchedule", current: false },
    { name: "History", href: "history", current: true },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const renderQuoteStatus = (status) => {
    switch (status) {
      case "draft":
        return "Draft";
      case "approval":
        return "Awaiting Manager Approval";
      case "returned":
        return "Returned by Manager";
      case "approved":
        return "Approved by Manager";
      case "sent":
        return "Sent to Customer";
      case "accepted":
        return "Accepted by Customer";
      case "declined":
        return "Declined by Customer";
      case "void":
        return "VOID";
      case "jobCreated":
        return "Job Created";
      default:
        return "Unknown";
    }
  };

  const renderJobStatus = (status) => {
    switch (status) {
      case "open":
        return "Open";
      case "invoiced":
        return "Invoiced";
      case "void":
        return "VOID";
      case "complete":
        return "Completed";
      default:
        return "Unknown";
    }
  };

  const renderInvoiceStatus = (status) => {
    switch (status) {
      case "draft":
        return "Draft";
      case "ready":
        return "Ready";
      case "void":
        return "VOID";
      case "overdue":
        return "Overdue";
      case "sent":
        return "Sent";
      case "paid":
        return "Paid";
      case "uncollectible":
        return "Uncollectible";
      default:
        return "Unknown";
    }
  };

  const renderStatus = (status, type) => {
    switch (type) {
      case "quote":
        return renderQuoteStatus(status);
      case "job":
        return renderJobStatus(status);
      case "invoice":
        return renderInvoiceStatus(status);
      default:
        return "Unknown";
    }
  };

  const renderEqType = () => {
    return equipment.equipmentType === "airCompressor"
      ? "Air Compressor"
      : equipment.equipmentType === "pressureWasher"
        ? "Pressure Washer"
        : equipment.equipmentType[0].toUpperCase() + equipment.equipmentType.slice(1);
  };

  const runHistoryQuery = (data, offset, limit) => {
    QueryEquipmentHistory({
      equipmentId: data.equipmentId,
      description: data.description,
      status: data.status,
      type: data.type,
      page: offset,
      limit: limit,
    })
      .then((res) => {
        setHistoryData(res.data.results);
        setTotal(res.data.total);
        setSize(res.data.size);
        setPage(res.data.page);
        setTimeout(() => setQueryLoading(false), 700);
      })
      .catch((err) => {
        toast.error("Failed to equipment history");
        setHistoryData([]);
        setTotal(0);
        setSize(DEFAULT_SIZE);
        setPage(0);
        setFilters(BLANK_FILTERS);
        setTimeout(() => setQueryLoading(false), 700);
      });
  };

  const changePage = (pg, sz) => {
    setPage(pg);
    setSize(sz);
    setQueryLoading(true);
    runHistoryQuery(filters, pg, sz);
  };

  const openElement = (type, id) => {
    switch (type) {
      case "quote":
        navigate(`/quotes/${id}`);
        break;
      case "job":
        navigate(`/jobs/${id}`);
        break;
      case "invoice":
        navigate(`/invoices/${id}`);
        break;
      default:
        toast.error("Something went wrong");
        break;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      applyFilters();
    }
  };

  const updateType = (v) => {
    let tmp = filters;
    if (v) {
      tmp.type = v;
    } else {
      tmp.type = null;
    }
    setFilters(tmp);
    runHistoryQuery(tmp, page, size);
  };

  const updateStatus = (v) => {
    let tmp = filters;
    if (v) {
      tmp.status = v;
    } else {
      tmp.status = null;
    }
    setFilters(tmp);
    runHistoryQuery(tmp, page, size);
  };

  const updateFilter = ({ target: { name, value } }) => {
    let tmp = filters;
    tmp[name] = value;
    setFilters((prev) => ({ ...prev, [name]: value }));
    runHistoryQuery(tmp, page, size);
  };

  const clearFilters = () => {
    setQueryLoading(true);
    setFilters({ ...BLANK_FILTERS, equipmentId: equipmentId });
    setPage(0);
    setSize(DEFAULT_SIZE);
    runHistoryQuery({ ...BLANK_FILTERS, equipmentId: equipmentId }, 0, DEFAULT_SIZE);
  };

  const applyFilters = () => {
    setQueryLoading(true);
    setPage(0);
    setSize(DEFAULT_SIZE);
    runHistoryQuery(filters, 0, DEFAULT_SIZE);
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      {loading ? (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold uppercase animate-pulse">Loading</p>
        </div>
      ) : (
        <>
          <Helmet>
            <title>
              {renderEqType()} History - {customer.customerCode} | HTPS ERP
            </title>
          </Helmet>
          <div className="flex flex-row items-center justify-start w-full mb-3">
            <div className="w-full sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full border-gray-300 rounded-md focus:border-blue-500 focus:ring-blue-500"
                defaultValue={tabs.find((tab) => tab.current).href}
                onChange={(v) => navigate(`/equipment/${equipmentId}/${v.target.value}`)}
              >
                {tabs.map((tab) => {
                  if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                    return;
                  }
                  return <option value={tab.href}>{tab.name}</option>;
                })}
              </select>
            </div>
            <div className="hidden w-full sm:block">
              <div className="border-b border-gray-200">
                <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => {
                    if ((tab.name === "ATS" && equipment.equipmentType === "truck") || (tab.name === "ATS" && equipment.equipmentType === "trailer")) {
                      return;
                    }
                    return (
                      <p
                        key={tab.name}
                        onClick={() => navigate(`/equipment/${equipmentId}/${tab.href}`)}
                        className={classNames(
                          tab.current ? "border-blue-700 text-blue-700" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer",
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </p>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
          <div className="grid w-full h-full gap-6 px-1 mx-auto md:grid-cols-12">
            <div className="flex flex-col h-full col-span-3 gap-4">
              <div className="flex flex-col items-start justify-start w-full gap-4 p-6 border border-gray-300 rounded-lg">
                <h3 className="text-2xl font-semibold leading-none tracking-tight whitespace-nowrap">Filters</h3>
                <div className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                    Description
                  </label>
                  <Input
                    placeholder="Description"
                    name="description"
                    autoComplete="off"
                    onChange={(e) => {
                      updateFilter(e);
                    }}
                    value={filters.description}
                    defaultValue={filters.description}
                    onKeyDown={(e) => handleKeyDown(e)}
                    className="w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                    allowClear
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full">
                  <label htmlFor="type" className="pb-1 text-xs text-gray-600 uppercase">
                    Type
                  </label>
                  <Select
                    placeholder="Type"
                    onChange={(v) => updateType(v)}
                    value={filters.type}
                    options={[
                      {
                        label: "Invoice",
                        value: "invoice",
                      },
                      {
                        label: "Job",
                        value: "job",
                      },
                      {
                        label: "Quote",
                        value: "quote",
                      },
                    ]}
                    className="w-full mb-2 font-sans"
                    controls={false}
                    filterOption={false}
                    onKeyDown={(e) => handleKeyDown(e)}
                    defaultActiveFirstOption={false}
                    allowClear
                  />
                </div>
              </div>
              <div className="flex items-center justify-between w-full gap-2">
                <SecondaryButton
                  callback={() => clearFilters()}
                  label="Clear Filters"
                  className="block py-2.5 transition-all duration-300 normal-case w-full"
                />
                <PrimaryButton
                  callback={() => applyFilters()}
                  label="Apply Filters"
                  className="block py-2.5 transition-all duration-300 bg-black hover:bg-black/80 normal-case w-full"
                />
              </div>
            </div>
            <div className="flex flex-col h-full col-span-9 gap-4">
              <Table
                dataSource={historyData}
                loading={queryLoading}
                className="w-full"
                sticky={true}
                pagination={{
                  total: total,
                  responsive: true,
                  hideOnSinglePage: true,
                  onChange: (p, s) => changePage(p - 1, s),
                  pageSize: size,
                  current: page + 1,
                  showSizeChanger: false,
                }}
                rowKey="id"
              >
                <Table.Column
                  title="Type"
                  dataIndex="type"
                  key="type"
                  render={(d) => (
                    <div className="flex items-center justify-center py-1.5 border rounded-md border-slate-300 bg-slate-100 text-slate-600/90">
                      <p className="text-xs font-medium">{d.toUpperCase()}</p>
                    </div>
                  )}
                  width={"100px"}
                />
                <Table.Column title="Number" dataIndex="code" key="code" width={"150px"} />
                <Table.Column title="Status" dataIndex="status" key="status" width={"150px"} render={(v, r) => renderStatus(v, r.type)} />
                <Table.Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                  render={(v) => (
                    <Tooltip title={v}>
                      <p className="truncate">{v}</p>
                    </Tooltip>
                  )}
                />
                <Table.Column
                  title=""
                  dataIndex="id"
                  key="actions"
                  width={"90px"}
                  render={(v, r) => (
                    <SecondaryButton
                      callback={() => openElement(r.type, r.id)}
                      label="Open"
                      className="transition-all duration-300 border bg-white-smoke border-zinc-400 ring-0 hover:ring-0 hover:bg-zinc-100 hover:border-zinc-600 text-zinc-600 hover:text-zinc-900"
                    />
                  )}
                />
              </Table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EquipmentHistory;
