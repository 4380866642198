import { useState, useEffect, Fragment } from "react";
import { Search } from "iconoir-react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { Button, DatePicker, Input, Modal, Select, Switch, Table, TimePicker } from "antd";
import dayjs from "dayjs";
import { GetPayPeriods, LogTime, LogRNM, LogPartialTime } from "../../actions/auth";
import "./calendarStyle.css";
import timezone from "dayjs/plugin/timezone";
import weekday from "dayjs/plugin/weekday";
import customParseFormat from "dayjs/plugin/customParseFormat";
import en from "dayjs/locale/en";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import { Helmet } from "react-helmet-async";

dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
dayjs.locale({ ...en, weekStart: 1 });
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);

const _ = require("lodash");

const Timesheets = ({ authState, authDispatch }) => {
  const [loading, setLoading] = useState(true);
  const [currentPeriod, setCurrentPeriod] = useState({});
  const [drawer, setDrawer] = useState(false);
  const [labor, setLabor] = useState([]);
  const [logRNM, setLogRNM] = useState(false);
  const [newEntry, setNewEntry] = useState({
    date: null,
    timeIn: null,
    timeOut: null,
    lunchOut: null,
    lunchIn: null,
    entryId: "",
    skipLunch: false,
    partialEntry: false,
  });
  const [rnmEntry, setRNMEntry] = useState({
    date: null,
    start: null,
    end: null,
    description: "",
  });
  const [dummyLoading, setDummyLoading] = useState(false);
  const [timeOff, setTimeOff] = useState([]);
  const [olderTimesheets, setOlderTimesheets] = useState([]);
  const [olderTimesheetsModal, setOlderTimesheetsModal] = useState(false);
  const [periodQuery, setPeriodQuery] = useState("");

  useEffect(() => {
    let inView = true;
    if (inView) {
      GetPayPeriods()
        .then((res) => {
          setLabor(res.data.labor);
          let tmpOldTimesheets = res.data.olderTimesheets.reverse();
          setOlderTimesheets(tmpOldTimesheets);
          setCurrentPeriod(res.data.payPeriod);
          setTimeOff(res.data.timeOff);
          setTimeout(() => setLoading(false), 700);
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
          setLoading(false);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  const refreshData = () => {
    GetPayPeriods()
      .then((res) => {
        setLabor(res.data.labor);
        let tmpOldTimesheets = res.data.olderTimesheets.reverse();
        setOlderTimesheets(tmpOldTimesheets);
        setCurrentPeriod(res.data.payPeriod);
        setTimeOff(res.data.timeOff);
        setTimeout(() => setLoading(false), 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const getPeriodStatus = () => {
    switch (currentPeriod.status) {
      case "Not Reviewed":
        return (
          <span className="inline-flex mt-0.5 items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-500/10">
            Not Reviewed
          </span>
        );
      case "Approved":
        return (
          <span className="inline-flex mt-0.5 items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
            Approved
          </span>
        );
      case "Rejected":
        return (
          <span className="inline-flex mt-0.5 items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
            Badge
          </span>
        );
      default:
        return (
          <span className="inline-flex mt-0.5 items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            Unknown Status
          </span>
        );
    }
  };

  const closeDrawer = () => {
    setDrawer(false);
    setNewEntry({
      date: null,
      timeIn: null,
      timeOut: null,
      lunchOut: null,
      lunchIn: null,
      entryId: "",
      skipLunch: false,
      partialEntry: false,
    });
    refreshData();
  };

  const closeRNM = () => {
    setLogRNM(false);
    setRNMEntry({
      date: null,
      start: null,
      end: null,
      description: "",
    });
    refreshData();
  };

  const renderLogTime = () => {
    return (
      <Modal title="Log Time" open={drawer} onCancel={() => closeDrawer()} onOk={() => addNewTimeEntry()} okText="Submit" centered destroyOnClose>
        <div className="relative flex flex-col items-start justify-between h-full gap-4 my-6">
          <div className="flex flex-col items-start justify-start w-full gap-4">
            {!dummyLoading && (
              <>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-medium uppercase">Date you worked</p>
                  <DatePicker
                    format={"MM/DD/YYYY"}
                    onChange={(date, dateString) => updateDate(dateString)}
                    className="w-1/2"
                    changeOnBlur={true}
                    defaultValue={newEntry.date && dayjs(newEntry.date, "MM/DD/YYYY").isValid() ? dayjs(newEntry.date, "MM/DD/YYYY") : null}
                  />
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-col items-start justify-center gap-1">
                    <p className="text-sm font-medium uppercase">Partial Entry</p>
                    <p className="w-2/3 text-xs text-gray-400">Check this box if you're not ready to provide all information at this time</p>
                  </div>
                  <Switch defaultChecked={newEntry.partialEntry} onChange={(v) => setNewEntry({ ...newEntry, partialEntry: v })} />
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <div className="flex flex-col items-start justify-center gap-1">
                    <p className="text-sm font-medium uppercase">Skip Lunch</p>
                    <p className="w-2/3 text-xs text-gray-400">Check this box if you did not take lunch on this day</p>
                  </div>
                  <Switch defaultChecked={newEntry.skipLunch} onChange={(v) => setNewEntry({ ...newEntry, skipLunch: v })} />
                </div>
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-medium uppercase">Clock-In Time</p>
                  <TimePicker
                    format={"HH:mm"}
                    onChange={(time, timeString) => updateTime("timeIn", timeString)}
                    defaultValue={newEntry.timeIn && dayjs(newEntry.timeIn).isValid() ? newEntry.timeIn : null}
                    className="w-1/2"
                    changeOnBlur={true}
                  />
                </div>
                {!newEntry.skipLunch && (
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm font-medium uppercase">Lunch-Out Time</p>
                    <TimePicker
                      defaultValue={newEntry.lunchOut && dayjs(newEntry.lunchOut).isValid() ? newEntry.lunchOut : null}
                      format={"HH:mm"}
                      onChange={(time, timeString) => updateTime("lunchOut", timeString)}
                      className="w-1/2"
                      changeOnBlur={true}
                    />
                  </div>
                )}
                {!newEntry.skipLunch && (
                  <div className="flex flex-row items-center justify-between w-full">
                    <p className="text-sm font-medium uppercase">Lunch-In Time</p>
                    <TimePicker
                      defaultValue={newEntry.lunchIn && dayjs(newEntry.lunchIn).isValid() ? newEntry.lunchIn : null}
                      format={"HH:mm"}
                      onChange={(time, timeString) => updateTime("lunchIn", timeString)}
                      className="w-1/2"
                      changeOnBlur={true}
                    />
                  </div>
                )}
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-medium uppercase">Clock-Out Time</p>
                  <TimePicker
                    defaultValue={newEntry.timeOut && dayjs(newEntry.timeOut).isValid() ? newEntry.timeOut : null}
                    format={"HH:mm"}
                    onChange={(time, timeString) => updateTime("timeOut", timeString)}
                    className="w-1/2"
                    changeOnBlur={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const renderLogRNM = () => {
    return (
      <Modal open={logRNM} onCancel={() => closeRNM()} onOk={() => addNewRNMEntry()} okText="Submit" centered title="Log R&M Time">
        <div className="relative flex flex-col items-start justify-between h-full gap-4 my-6">
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <div className="flex flex-row items-center justify-between w-full">
              <p className="text-sm font-medium uppercase">Date</p>
              <DatePicker format={"MM/DD/YYYY"} onChange={(date, dateString) => updateRNMDate(date)} className="w-1/2" changeOnBlur={true} />
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="text-sm font-medium uppercase">R&M Start Time</p>
              <TimePicker
                defaultValue={null}
                format={"HH:mm"}
                onChange={(time, timeString) => updateRNMTime("start", time)}
                className="w-1/2"
                changeOnBlur={true}
              />
            </div>
            <div className="flex flex-row items-center justify-between w-full">
              <p className="text-sm font-medium uppercase">R&M End Time</p>
              <TimePicker
                defaultValue={null}
                format={"HH:mm"}
                onChange={(time, timeString) => updateRNMTime("end", time)}
                className="w-1/2"
                changeOnBlur={true}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              <p className="text-sm font-medium uppercase">R&M Description</p>
              <Input.TextArea
                rows={4}
                className="!resize-none"
                placeholder="Please describe what you worked on"
                onChange={(e) => updateRNMDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const updateDate = (value) => {
    setDummyLoading(true);
    let tmp = newEntry;
    tmp.date = value;
    let dt = dayjs(value, "MM/DD/YYYY");
    let exists = checkForEntry(value);
    if (exists) {
      if (exists.timeIn) {
        let timeIn = dayjs(exists.timeIn);
        tmp.timeIn = dt.hour(timeIn.hour()).minute(timeIn.minute());
      }
      if (exists.timeOut) {
        let timeOut = dayjs(exists.timeOut);
        tmp.timeOut = dt.hour(timeOut.hour()).minute(timeOut.minute());
      }
      if (exists.partialEntry) {
        tmp.partialEntry = true;
      }
      if (exists.skipLunch) {
        tmp.skipLunch = true;
      } else {
        if (exists.lunchOut) {
          let lunchOut = dayjs(exists.lunchOut);
          tmp.lunchOut = dt.hour(lunchOut.hour()).minute(lunchOut.minute());
        }
        if (exists.lunchIn) {
          let lunchIn = dayjs(exists.lunchIn);
          tmp.lunchIn = dt.hour(lunchIn.hour()).minute(lunchIn.minute());
        }
      }
      if (!exists.partialEntry) {
        toast.error("You have already logged a full entry for this date. Please contact your supervisor to make changes.");
        tmp.date = null;
        tmp.entryId = null;
        tmp.timeIn = null;
        tmp.timeOut = null;
        tmp.lunchOut = null;
        tmp.lunchIn = null;
        tmp.skipLunch = false;
        tmp.partialEntry = false;
      }
      if (exists.entryId) {
        tmp.entryId = exists.entryId;
      }
      setNewEntry(tmp);
      setTimeout(() => setDummyLoading(false), 700);
    } else {
      setDummyLoading(false);
      setNewEntry(tmp);
    }
  };

  const updateTime = (timeType, value) => {
    let tmp = newEntry;
    if (timeType === "timeIn") {
      tmp.timeIn = value;
    } else if (timeType === "timeOut") {
      tmp.timeOut = value;
    } else if (timeType === "lunchOut") {
      tmp.lunchOut = value;
    } else if (timeType === "lunchIn") {
      tmp.lunchIn = value;
    }
    setNewEntry(tmp);
  };

  const addNewTimeEntry = () => {
    let failed = false;
    if (!newEntry.date) {
      toast.error("Please select a date");
      failed = true;
    } else if (!newEntry.timeIn) {
      toast.error("Please select a time in");
      failed = true;
    } else if (!newEntry.partialEntry) {
      if (!newEntry.timeOut) {
        toast.error("Please select a time out");
        failed = true;
      } else if (!newEntry.skipLunch && !newEntry.lunchOut) {
        toast.error("Please select a time when you went to lunch");
        failed = true;
      } else if (!newEntry.skipLunch && !newEntry.lunchIn) {
        toast.error("Please select a time when you came back from lunch");
        failed = true;
      }
    }
    if (!failed) {
      if (!newEntry.partialEntry && !newEntry.skipLunch) {
        setLoading(true);
        let timeIn;
        let timeOut;
        let lunchIn;
        let lunchOut;
        if (!dayjs(newEntry.timeIn).isValid()) {
          timeIn = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", parseInt(newEntry.timeIn.split(":")[0]))
            .set("minute", parseInt(newEntry.timeIn.split(":")[1]))
            .set("second", 0);
        } else {
          timeIn = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", dayjs(newEntry.timeIn).hour())
            .set("minute", dayjs(newEntry.timeIn).minute())
            .set("second", 0);
        }
        if (!dayjs(newEntry.timeOut).isValid()) {
          timeOut = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", parseInt(newEntry.timeOut.split(":")[0]))
            .set("minute", parseInt(newEntry.timeOut.split(":")[1]))
            .set("second", 0);
        } else {
          timeOut = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", dayjs(newEntry.timeOut).hour())
            .set("minute", dayjs(newEntry.timeOut).minute())
            .set("second", 0);
        }
        if (!dayjs(newEntry.lunchIn).isValid()) {
          lunchIn = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", parseInt(newEntry.lunchIn.split(":")[0]))
            .set("minute", parseInt(newEntry.lunchIn.split(":")[1]))
            .set("second", 0);
        } else {
          lunchIn = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", dayjs(newEntry.lunchIn).hour())
            .set("minute", dayjs(newEntry.lunchIn).minute())
            .set("second", 0);
        }
        if (!dayjs(newEntry.lunchOut).isValid()) {
          lunchOut = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", parseInt(newEntry.lunchOut.split(":")[0]))
            .set("minute", parseInt(newEntry.lunchOut.split(":")[1]))
            .set("second", 0);
        } else {
          lunchOut = dayjs(newEntry.date, "MM/DD/YYYY")
            .set("hour", dayjs(newEntry.lunchOut).hour())
            .set("minute", dayjs(newEntry.lunchOut).minute())
            .set("second", 0);
        }
        if (timeOut.isAfter(timeIn) && timeOut.isAfter(lunchOut) && timeOut.isAfter(lunchIn)) {
          if (lunchOut.isAfter(timeIn) && lunchIn.isAfter(timeIn) && lunchIn.isBefore(timeOut)) {
            failed = false;
          } else {
            failed = true;
          }
        } else {
          failed = true;
        }
        let payload = {
          date: newEntry.date,
          timeIn: timeIn.toJSON(),
          timeOut: timeOut.toJSON(),
          lunchOut: lunchOut.toJSON(),
          lunchIn: lunchIn.toJSON(),
        };
        let timesheetId = currentPeriod.timesheetId;
        if (failed) {
          toast.error("Something went wrong, please try again");
          setLoading(false);
        } else {
          if (newEntry.entryId && newEntry.entryId.length > 0) {
            payload.entryId = newEntry.entryId;
            LogPartialTime(currentPeriod.timesheetId, payload)
              .then((res) => {
                toast.success("Time logged successfully!");
                closeDrawer();
                refreshData();
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
                setLoading(false);
              });
          } else {
            LogTime(timesheetId, payload)
              .then((res) => {
                toast.success("Time logged successfully!");
                closeDrawer();
                refreshData();
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
                setLoading(false);
              });
          }
        }
      } else if (newEntry.partialEntry) {
        if (!checkForEntry(newEntry.date) && !dayjs(newEntry.date).isToday()) {
          toast.error("Partial entries can only be logged for today's date");
        } else if (!checkForEntry(newEntry.date) && !newEntry.timeIn) {
          toast.error("Please enter your time in");
        } else {
          let payload = {
            date: newEntry.date,
          };
          let existing = checkForEntry(newEntry.date);
          if (dayjs(newEntry.timeIn, "HH:mm").isValid()) {
            let timeIn = dayjs(newEntry.timeIn, "HH:mm");
            payload.timeIn = dayjs(newEntry.date, "MM/DD/YYYY").hour(timeIn.hour()).minute(timeIn.minute()).toJSON();
          }
          if (!newEntry.skipLunch) {
            payload.skipLunch = false;
            if (newEntry.lunchOut || newEntry.lunchIn) {
              let lunchOut;
              let lunchIn;
              let timeOut;
              if (newEntry.lunchOut) {
                if (dayjs(newEntry.lunchOut).isValid()) {
                  lunchOut = dayjs(newEntry.lunchOut);
                } else if (dayjs(newEntry.lunchOut, "HH:mm").isValid()) {
                  let tmpLunchOut = dayjs(newEntry.lunchOut, "HH:mm");
                  lunchOut = dayjs(newEntry.date, "MM/DD/YYYY").hour(tmpLunchOut.hour()).minute(tmpLunchOut.minute());
                }
              }
              if (newEntry.lunchIn) {
                if (dayjs(newEntry.lunchIn).isValid()) {
                  lunchIn = dayjs(newEntry.lunchIn);
                } else if (dayjs(newEntry.lunchIn, "HH:mm").isValid()) {
                  let tmpLunchIn = dayjs(newEntry.lunchIn, "HH:mm");
                  lunchIn = dayjs(newEntry.date, "MM/DD/YYYY").hour(tmpLunchIn.hour()).minute(tmpLunchIn.minute());
                }
              }
              if (newEntry.timeOut) {
                if (dayjs(newEntry.timeOut).isValid()) {
                  timeOut = dayjs(newEntry.timeOut);
                } else if (dayjs(newEntry.timeOut, "HH:mm").isValid()) {
                  let tmpTimeOut = dayjs(newEntry.timeOut, "HH:mm");
                  timeOut = dayjs(newEntry.date, "MM/DD/YYYY").hour(tmpTimeOut.hour()).minute(tmpTimeOut.minute());
                }
              }
              if (lunchOut && dayjs(lunchOut).isValid()) {
                if (lunchOut.isSameOrAfter(newEntry.timeIn)) {
                  payload.lunchOut = lunchOut;
                } else {
                  failed = true;
                  toast.error("Lunch out time must be after your clock-in time in");
                }
              }
              if (lunchIn && dayjs(lunchIn).isValid()) {
                if (!payload.lunchOut) {
                  failed = true;
                  toast.error("You must enter a lunch out time first");
                } else {
                  if (lunchIn.isSameOrAfter(payload.lunchOut)) {
                    payload.lunchIn = lunchIn;
                  } else {
                    failed = true;
                    toast.error("Lunch in time must be after your lunch out time");
                  }
                }
              }
              if (timeOut && dayjs(timeOut).isValid()) {
                if (!payload.lunchIn) {
                  failed = true;
                  toast.error("You must enter when you returned from lunch first");
                } else {
                  if (timeOut.isSameOrAfter(payload.lunchIn)) {
                    payload.timeOut = timeOut;
                  } else {
                    failed = true;
                    toast.error("Clock-Out time must be after the time you returned from lunch");
                  }
                }
              }
            }
          } else {
            payload.skipLunch = true;
            let timeOut;
            if (newEntry.timeOut) {
              if (dayjs(newEntry.timeOut).isValid()) {
                timeOut = dayjs(newEntry.timeOut);
              } else if (dayjs(newEntry.timeOut, "HH:mm").isValid()) {
                let tmpTimeOut = dayjs(newEntry.timeOut, "HH:mm");
                timeOut = dayjs(newEntry.date, "MM/DD/YYYY").hour(tmpTimeOut.hour()).minute(tmpTimeOut.minute());
              }
            }
            if (timeOut) {
              if (timeOut.isSameOrAfter(newEntry.timeIn)) {
                payload.timeOut = timeOut;
              } else {
                failed = true;
                toast.error("Clock-out time must be after your clock-in time");
              }
            }
          }
          if (!failed) {
            if (existing.entryId) {
              payload.entryId = existing.entryId;
            }
            setLoading(true);
            LogPartialTime(currentPeriod.timesheetId, payload)
              .then((res) => {
                toast.success("Time logged successfully!");
                closeDrawer();
                refreshData();
              })
              .catch((err) => {
                toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
                setLoading(false);
              });
          }
        }
      } else if (newEntry.skipLunch) {
        let payload = {
          date: newEntry.date,
          skipLunch: newEntry.skipLunch,
        };
        if (dayjs(newEntry.timeIn, "HH:mm").isValid()) {
          let timeIn = dayjs(newEntry.timeIn, "HH:mm");
          payload.timeIn = dayjs(newEntry.date, "MM/DD/YYYY").hour(timeIn.hour()).minute(timeIn.minute()).toJSON();
        } else {
          failed = true;
          toast.error("Please enter your clock-in time");
        }
        if (dayjs(newEntry.timeOut, "HH:mm").isValid()) {
          let timeOut = dayjs(newEntry.timeOut, "HH:mm");
          timeOut = dayjs(newEntry.date, "MM/DD/YYYY").hour(timeOut.hour()).minute(timeOut.minute());
          if (timeOut.isSameOrAfter(dayjs(payload.timeIn))) {
            payload.timeOut = timeOut.toJSON();
          } else {
            failed = true;
            toast.error("Clock-out time must be after your clock-in time");
          }
        } else {
          failed = true;
          toast.error("Please enter your clock-out time");
        }
        if (!failed) {
          if (newEntry.entryId) {
            payload.entryId = newEntry.entryId;
          }
          setLoading(true);
          LogPartialTime(currentPeriod.timesheetId, payload)
            .then((res) => {
              toast.success("Time logged successfully!");
              closeDrawer();
              refreshData();
            })
            .catch((err) => {
              toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
              setLoading(false);
            });
        }
      }
    }
  };

  const checkForEntry = (dt) => {
    let date = dayjs(dt);
    let entries = currentPeriod.entries;
    let entry = entries.find((e) => dayjs(e.date).format("MM/DD/YYYY") === date.format("MM/DD/YYYY"));
    if (entry) {
      return entry;
    } else {
      return false;
    }
  };

  const updateRNMDate = (value) => {
    let tmp = rnmEntry;
    tmp.date = value.toJSON();
    setRNMEntry(tmp);
  };

  const updateRNMTime = (timeType, value) => {
    let tmp = rnmEntry;
    if (timeType === "start") {
      tmp.start = value.toJSON();
    } else if (timeType === "end") {
      tmp.end = value.toJSON();
    }
    setRNMEntry(tmp);
  };

  const updateRNMDescription = (value) => {
    let tmp = rnmEntry;
    tmp.description = value;
    setRNMEntry(tmp);
  };

  const addNewRNMEntry = () => {
    if (!rnmEntry.date) {
      toast.error("Please select a date for the R&M Entry");
    } else if (!rnmEntry.start) {
      toast.error("Please select a start time for the R&M Entry");
    } else if (!rnmEntry.end) {
      toast.error("Please select an end time for the R&M Entry");
    } else if (rnmEntry.description.length < 5) {
      toast.error("Please describe the work you did under this R&M Entry");
    } else {
      let timesheetId = currentPeriod.timesheetId;
      let tmp = rnmEntry;
      let date = dayjs(tmp.date).second(0).millisecond(0);
      let start = dayjs(tmp.start).second(0).millisecond(0);
      date = date.hour(start.hour()).minute(start.minute());
      start = date.hour(start.hour()).minute(start.minute());
      let end = dayjs(tmp.end).second(0).millisecond(0);
      end = date.hour(end.hour()).minute(end.minute());
      let toSend = {
        date: date.toJSON(),
        start: start.toJSON(),
        end: end.toJSON(),
        description: tmp.description,
      };
      setLoading(true);
      LogRNM(timesheetId, toSend)
        .then((res) => {
          toast.success("R&M Entry logged successfully!");
          closeRNM();
          refreshData();
        })
        .catch((err) => {
          toast.error(err.response.data.message ? err.response.data.message : "Error fetching data. Please try again");
          setLoading(false);
        });
    }
  };

  const getHoursWorked = () => {
    let minutes = 0;
    let entries = currentPeriod.entries;
    if (entries) {
      for (let i = 0; i < entries.length; i++) {
        let timeIn = dayjs(entries[i].timeIn);
        let timeOut = dayjs(entries[i].timeOut);
        let lunchIn = dayjs(entries[i].lunchIn);
        let lunchOut = dayjs(entries[i].lunchOut);
        if (entries[i].skipLunch === false) {
          let preLunch = lunchOut.diff(timeIn, "minutes", true);
          let postLunch = timeOut.diff(lunchIn, "minutes", true);
          minutes = minutes + preLunch + postLunch;
        } else {
          let total = timeOut.diff(timeIn, "minutes", true);
          minutes = minutes + total;
        }
      }
    }
    for (let i = 0; i < currentPeriod.rnm.length; i++) {
      let el = currentPeriod.rnm[i];
      let start = dayjs(`2000-01-01 ${el.start}`, "YYYY-MM-DD HH:mm");
      let end = dayjs(`2000-01-01 ${el.end}`, "YYYY-MM-DD HH:mm");
      let rnmTime = end.diff(start, "minutes", true);
      minutes = minutes + rnmTime;
    }
    let hrs = Math.floor(minutes / 60);
    let mins = minutes - hrs * 60;
    if (mins >= 8 && mins <= 22) {
      return `${hrs}.25`;
    } else if (mins >= 23 && mins <= 37) {
      return `${hrs}.50`;
    } else if (mins >= 38 && mins <= 52) {
      return `${hrs}.75`;
    } else if (mins >= 53) {
      hrs++;
      return `${hrs}.00`;
    } else if (mins >= 0 && mins <= 7) {
      return `${hrs}.00`;
    }
  };

  const calculateHours = (entry) => {
    let minutes = 0;
    let timeIn = dayjs(entry.timeIn);
    let timeOut = dayjs(entry.timeOut);
    let lunchIn = dayjs(entry.lunchIn);
    let lunchOut = dayjs(entry.lunchOut);
    if (entry.skipLunch) {
      let total = timeOut.diff(timeIn, "minutes", true);
      minutes = minutes + total;
    } else {
      let preLunch = lunchOut.diff(timeIn, "minutes", true);
      let postLunch = timeOut.diff(lunchIn, "minutes", true);
      minutes = minutes + preLunch + postLunch;
    }
    let hrs = Math.floor(minutes / 60);
    let mins = minutes - hrs * 60;
    if (mins >= 8 && mins <= 22) {
      return `${hrs}.25`;
    } else if (mins >= 23 && mins <= 37) {
      return `${hrs}.50`;
    } else if (mins >= 38 && mins <= 52) {
      return `${hrs}.75`;
    } else if (mins >= 53) {
      hrs++;
      return `${hrs}.00`;
    } else {
      return `${hrs}.00`;
    }
  };

  const calculateOT = () => {
    let totalHrs = getHoursWorked();
    totalHrs = parseFloat(totalHrs);
    if (totalHrs > 80) {
      return totalHrs - 80;
    } else {
      return 0;
    }
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Job #",
        dataIndex: "jobNo",
        key: "jobNo",
        className: "w-3/5",
      },
      {
        title: "Clock-In",
        dataIndex: "clockIn",
        key: "clockIn",
      },
      {
        title: "Clock-Out",
        key: "clockOut",
        dataIndex: "clockOut",
      },
      {
        title: "Job Finished",
        dataIndex: "jobCompleted",
        key: "jobCompleted",
      },
      {
        title: "Hours",
        key: "hours",
        dataIndex: "hours",
      },
    ];
    let data = [];
    let hasLabor = labor.filter((l) => dayjs(l.laborDate).format("MM/DD/YYYY") === record.date);
    if (hasLabor.length > 0 || record.rnm.length > 0) {
      for (let i = 0; i < hasLabor.length; ++i) {
        let el = hasLabor[i];
        let hours = "0.00";
        let start = dayjs(el.clockIn);
        let end = dayjs(el.clockOut);
        let difference = end.diff(start, "minute");
        let hrs = Math.floor(difference / 60);
        let mins = difference - hrs * 60;
        if (mins >= 8 && mins <= 22) {
          hours = `${hrs}.25`;
        } else if (mins >= 23 && mins <= 37) {
          hours = `${hrs}.50`;
        } else if (mins >= 38 && mins <= 52) {
          hours = `${hrs}.75`;
        } else if (mins >= 53) {
          hrs++;
          hours = `${hrs}.00`;
        } else if (mins >= 0 && mins < 8) {
          hours = `${hrs}.00`;
        }
        data.push({
          key: el.logId,
          jobNo: el.jobNo,
          clockIn: dayjs(el.clockIn).format("hh:mm A"),
          clockOut: dayjs(el.clockOut).format("hh:mm A"),
          jobCompleted: el.jobCompleted ? "Yes" : "No",
          hours: `${hours} hrs`,
          sortBy: dayjs(el.clockIn).format("HH:mm"),
        });
      }
      if (record.rnm.length > 0) {
        for (let i = 0; i < record.rnm.length; ++i) {
          let el = record.rnm[i];
          let hours = "0.00";
          let start = dayjs(el.start);
          let end = dayjs(el.end);
          let difference = end.diff(start, "minute");
          let hrs = Math.floor(difference / 60);
          let mins = difference - hrs * 60;
          if (mins >= 8 && mins <= 22) {
            hours = `${hrs}.25`;
          } else if (mins >= 23 && mins <= 37) {
            hours = `${hrs}.50`;
          } else if (mins >= 38 && mins <= 52) {
            hours = `${hrs}.75`;
          } else if (mins >= 53) {
            hrs++;
            hours = `${hrs}.00`;
          } else if (mins >= 0 && mins < 8) {
            hours = `${hrs}.00`;
          }
          data.push({
            key: el.rnmId,
            jobNo: "R&M | " + (el.description.length > 40 ? el.description.substring(0, 40) + "..." : el.description),
            clockIn: dayjs(el.start).format("hh:mm A"),
            clockOut: dayjs(el.end).format("hh:mm A"),
            jobCompleted: "N/A",
            hours: `${hours} hrs`,
            sortBy: dayjs(el.start).format("HH:mm"),
          });
        }
      }

      data = data.sort((a, b) => a.sortBy.localeCompare(b.sortBy));
      return <Table columns={columns} dataSource={data} pagination={false} bordered size="small" />;
    } else {
      return <p className="w-full text-sm text-center text-gray-400">No labor entries for this day</p>;
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Time In",
      dataIndex: "timeIn",
      key: "timeIn",
    },
    {
      title: "Time Out",
      dataIndex: "timeOut",
      key: "timeOut",
    },
    {
      title: "Lunch Out",
      dataIndex: "lunchOut",
      key: "lunchOut",
    },
    {
      title: "Lunch In",
      dataIndex: "lunchIn",
      key: "lunchIn",
    },
    {
      title: "Hours",
      dataIndex: "hours",
      key: "hours",
    },
  ];
  const data = [];
  for (let i = 0; i < 14; ++i) {
    let startDate = dayjs(currentPeriod.startDate).add(i, "day");
    let hasEntry = currentPeriod.entries?.find((e) => dayjs(e.date).format("MM/DD/YYYY") === startDate.format("MM/DD/YYYY"));
    let hours = "0.00";
    if (hasEntry && !hasEntry.partialEntry) {
      hours = calculateHours(hasEntry);
    }
    let rnmTmp = [];
    if (currentPeriod.rnm) {
      for (let j = 0; j < currentPeriod.rnm.length; j++) {
        let rnmEntry = currentPeriod.rnm[j];
        if (dayjs(rnmEntry.date).format("MM/DD/YYYY") === startDate.format("MM/DD/YYYY")) {
          rnmTmp.push(rnmEntry);
        }
      }
    }
    let hasPTO = timeOff.some((t) => dayjs(t.startDate).isSameOrBefore(startDate, "day") && dayjs(t.endDate).isSameOrAfter(startDate, "day"));

    data.push({
      key: startDate.format("MM/DD/YYYY [ | ] ddd"),
      date: startDate.format("MM/DD/YYYY"),
      timeIn: hasPTO ? "N/A - Off Day" : hasEntry ? dayjs(hasEntry.timeIn).format("hh:mm A") : "Not Entered",
      timeOut: hasPTO ? "N/A - Off Day" : hasEntry && hasEntry.timeOut ? dayjs(hasEntry.timeOut).format("hh:mm A") : "Not Entered",
      lunchOut: hasPTO ? "N/A - Off Day" : hasEntry && hasEntry.lunchOut && !hasEntry.skipLunch ? dayjs(hasEntry.lunchOut).format("hh:mm A") : "Not Entered",
      lunchIn: hasPTO ? "N/A - Off Day" : hasEntry && hasEntry.lunchIn && !hasEntry.skipLunch ? dayjs(hasEntry.lunchIn).format("hh:mm A") : "Not Entered",
      hours: hasPTO ? "N/A" : hours + " hrs",
      rnm: rnmTmp,
    });
  }

  const closeOlderTimesheets = () => {
    setOlderTimesheetsModal(false);
    setPeriodQuery("");
  };

  return !loading ? (
    <div className="flex flex-col items-start justify-start w-full h-full">
      <Helmet>
        <title>Timesheet - {currentPeriod.periodName} | HTPS ERP</title>
      </Helmet>
      <div className="hidden w-full border-b border-gray-200 md:flex dark:border-gray-700">
        <nav className="flex space-x-2" aria-label="Tabs" role="tablist">
          <button
            className="hs-tab-active:font-semibold hs-tab-active:border-blue-600 hs-tab-active:text-blue-600 py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-blue-600"
            onClick={() => navigate("/settings")}
          >
            Profile Settings
          </button>
          <button
            className="font-semibold border-blue-600 text-blue-600 py-4 px-1 inline-flex items-center gap-2 border-b-[3px] border-transparent text-sm whitespace-nowrap hover:text-blue-600 active"
            onClick={() => navigate("/settings/timesheets")}
          >
            Timesheets
          </button>
        </nav>
      </div>
      <div className="flex flex-col items-center justify-between w-full px-1 py-5 mb-5 md:flex-row">
        <div className="flex flex-col items-start justify-center text-center">
          <h1 className="text-3xl font-bold">Timesheets</h1>
        </div>
        <div className="flex flex-col items-center justify-center text-center">
          <p className="text-sm font-semibold uppercase">Period: {currentPeriod.periodName}</p>
          <p className="text-xs font-medium uppercase">{getPeriodStatus()}</p>
        </div>
        <div className="flex items-center justify-end gap-2">
          {olderTimesheets.length > 0 && <SecondaryButton label="Older Timesheets" callback={() => setOlderTimesheetsModal(true)} />}
          <SecondaryButton label="Add R&M Time" callback={() => setLogRNM(true)} disabled={currentPeriod.status === "Approved"} />
          <SecondaryButton label="Log time" callback={() => setDrawer(true)} disabled={currentPeriod.status === "Approved"} />
        </div>
      </div>
      <p className="px-3 py-5 text-sm font-medium text-center border-gray-200 border-y md:hidden">
        To view your full timesheet, please log in from a computer.
      </p>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record),
        }}
        dataSource={data}
        className="hidden w-full md:table"
        pagination={false}
        bordered
      />
      {renderLogTime()}
      {renderLogRNM()}
      <div className="flex flex-row items-center justify-end w-full gap-4 px-2 mt-5">
        <div className="flex flex-col items-end justify-start gap-2">
          <p>
            Hours Worked: <span className="font-semibold">{getHoursWorked()}</span> hrs
          </p>
          <p>
            Overtime: <span className="font-semibold">{calculateOT().toFixed(2)}</span> hrs
          </p>
          <p className="pt-3 my-2 border-t border-gray-300">
            Total Hours: <span className="font-semibold">{getHoursWorked()}</span> hrs
          </p>
        </div>
      </div>
      <Modal
        open={olderTimesheetsModal}
        title="Older Timesheets"
        onCancel={() => closeOlderTimesheets()}
        okButtonProps={{ hidden: true }}
        destroyOnClose
        centered
        width={800}
        cancelText="Close"
      >
        <div className="flex flex-col items-start justify-start w-full gap-4">
          <div className="flex items-center justify-between w-full gap-4">
            <p>Select a period</p>
            <Select
              defaultValue={olderTimesheets[0].tsid}
              value={periodQuery === "" ? olderTimesheets[0].tsid : periodQuery}
              onChange={(v) => setPeriodQuery(v)}
              className="w-1/2"
              options={olderTimesheets.map((ots) => ({
                value: ots.tsid,
                label: ots.periodName,
              }))}
              placeholder="Select a timesheet period"
            />
          </div>
          <Table
            pagination={false}
            dataSource={periodQuery !== "" ? olderTimesheets.find((t) => t.tsid === periodQuery).entries : olderTimesheets[0].entries}
            bordered
            size="small"
            className="w-full"
          >
            <Table.Column title="Date" dataIndex="date" />
            <Table.Column title="Time In" dataIndex="timeIn" render={(r) => dayjs(r).format("H:mm")} />
            <Table.Column title="Time Out" dataIndex="timeOut" render={(r) => dayjs(r).format("H:mm")} />
            <Table.Column
              title="Lunch In"
              dataIndex={["lunchIn", "skipLunch"]}
              render={(txt, r) => (r.skipLunch ? "Skipped" : dayjs(r.lunchOut).isValid() ? dayjs(r.lunchOut).format("H:mm") : "Unknown")}
            />
            <Table.Column
              title="Lunch Out"
              dataIndex={["lunchOut", "skipLunch"]}
              render={(txt, r) => (r.skipLunch ? "Skipped" : dayjs(r.lunchOut).isValid() ? dayjs(r.lunchOut).format("H:mm") : "Unknown")}
            />
            <Table.Column
              title="Hours"
              dataIndex={["timeIn", "timeOut", "lunchIn", "lunchOut", "skipLunch"]}
              render={(txt, r) => (r.partialEntry ? "Incomplete Entry" : calculateHours(r))}
            />
          </Table>
        </div>
      </Modal>
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <p className="text-sm font-semibold text-gray-400 uppercase">Loading</p>
    </div>
  );
};

export default Timesheets;
