import { useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { CreateAJob, GetNextJobNo } from "../../actions/jobs";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { GetAllCustomers, GetOneCustomer } from "../../actions/customers";
import { Input, InputNumber, Modal, Select, Switch } from "antd";
import { ErrorMessage } from "@hookform/error-message";
import validator from "validator";
import { GetEmployees } from "../../actions/auth";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { GetTerminals, RetryCapture, AbortTransaction, SaleStatus, CheckCardSale, StartSale, CreateManualIntent } from "../../actions/payments";
import { BadgeCheck, Loader } from "lucide-react";
import { RefreshDouble } from "iconoir-react";
import { formatCurrency } from "../../components/tools";
import { MaskedInput } from "antd-mask-input";
import { EmailAReceipt, TextAReceipt } from "../../actions/util";
import { Helmet } from "react-helmet-async";

var stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const locations = [
  { value: "a6fe18dd-b809-4a78-85ab-e767e2b8ebcf", label: "Augusta, GA" },
  {
    value: "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f",
    label: "North Augusta, SC",
  },
];

const paymentTerms = [
  { value: "net10", label: "NET10" },
  { value: "net20", label: "NET20" },
  { value: "net30", label: "NET30" },
  { value: "collectOnDelivery", label: "Collect on Delivery" },
  { value: "dueOnReceipt", label: "Due on Receipt" },
  { value: "prePay", label: "Pre-Pay" },
];

const taxStatus = [
  { value: "taxable", label: "Taxable" },
  { value: "gov", label: "Government Entity" },
  { value: "st5biz", label: "Business w/ ST5" },
  { value: "outOfState", label: "Out of State" },
];

const testZip = (val) => {
  return /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/.test(val);
};

const NewJob = ({ authState, authDispatch }) => {
  let [loading, setLoading] = useState(true);
  let [dummyLoading, setDummyLoading] = useState(true);
  let [customers, setCustomers] = useState([]);
  let [employees, setEmployees] = useState([]);
  let [values, setValues] = useState({
    customerId: "",
    locationId: authState.user.locationId,
    description: "",
    details: "",
    eoNotes: "",
    clientPo: "",
    laborDiscount: 0,
    partsMarkup: 45,
    paymentTerms: "net10",
    tax: "",
    taxZip: "",
  });
  let [addedEquipment, setAddedEquipment] = useState([]);
  let [allEquipment, setAllEquipment] = useState([]);
  let [selectedCustomer, setSelectedCustomer] = useState(null);
  let [notesModal, setNotesModal] = useState(false);
  let [addEquipmentModal, setAddEquipmentModal] = useState(false);
  let [query, setQuery] = useState("");
  let [nextJobNo, setNextJobNo] = useState(null);
  let [paymentModal, setPaymentModal] = useState(false);
  let [paymentAmount, setPaymentAmount] = useState(null);
  let [paymentStep, setPaymentStep] = useState(0);
  let [saleId, setSaleId] = useState(null);
  let [saleIds, setSaleIds] = useState([]);
  let [processing, setProcessing] = useState(false);
  let [intent, setIntent] = useState(null);
  let [terminal, setTerminal] = useState(null);
  let [terminals, setTerminals] = useState([]);
  let [terminalLoad, setTerminalLoad] = useState(false);
  let [retryTimeout, setRetryTimeout] = useState(true);
  let [paymentError, setPaymentError] = useState("");
  let [emailReceipt, setEmailReceipt] = useState(false);
  let [smsReceipt, setSmsReceipt] = useState(false);
  let [receiptInfo, setReceiptInfo] = useState({
    email: null,
    phone: null,
  });
  let [odometerReadings, setOdometerReadings] = useState([]);
  let [logOdoId, setLogOdoId] = useState(null);
  let [logOdoReading, setLogOdoReading] = useState(null);
  let [logOdoModal, setLogOdoModal] = useState(false);

  let navigate = useNavigate();
  let [search, setSearch] = useSearchParams();

  let host = process.env.REACT_APP_HOST;

  useEffect(() => {
    let inView = true;
    if (inView) {
      if (authState.user.locationId === "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f") {
        setValues({ ...values, taxZip: "29860" });
      }
      GetAllCustomers()
        .then((res) => {
          setCustomers(res.data);
          let customerId = search.get("customerId");
          if (customerId) {
            let tmp = values;
            tmp.customerId = customerId;
            setValues(tmp);
            let cust = res.data.find((c) => c.customerId === customerId);
            if (cust) {
              setCustomer(cust);
            }
          }
          reloadTerminals();
          GetEmployees()
            .then((res) => {
              setEmployees(res.data);
              GetNextJobNo(authState.user.locationId)
                .then((res) => {
                  setNextJobNo(res.data.jobNo);
                  setTimeout(() => setLoading(false), 700);
                })
                .catch((err) => {
                  toast.error("Unable to retrieve the next job number.");
                  setTimeout(() => setLoading(false), 700);
                });
            })
            .catch((err) => {
              toast.error("An error has occurred while loading employees, please try again.");
              setTimeout(() => setLoading(false), 700);
            });
        })
        .catch((err) => {
          toast.error("An error has occurred while loading customers, please try again.");
          setTimeout(() => setLoading(false), 700);
        });
    }
    return () => {
      inView = false;
    };
  }, []);

  let submitForm = (data) => {
    setLoading(true);
    setValues(data);
    if (!data.clientPo) {
      data.clientPo = "";
    }
    if (!data.details) {
      data.details = "";
    }
    if (!data.eoNotes) {
      data.eoNotes = "";
    }
    if (addedEquipment.length > 0) {
      data.equipment = addedEquipment;
    }
    if (saleIds.length > 0) {
      data.payments = saleIds;
    }
    if (odometerReadings.length > 0) {
      data.odometerReadings = odometerReadings;
    }
    CreateAJob(data)
      .then((res) => {
        toast.success("Job created successfully! You will be redirected shortly.");
        navigate(`/jobs/${res.data.jobId}`);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while creating a job, please try again.");
        setLoading(false);
      });
  };

  const getTaxDefaultValue = () => {
    if (authState.user.locationId === "1f1bf36d-b744-4f69-bc9c-ca2c32a5f66f") {
      return "29860";
    } else {
      if (selectedCustomer) {
        if (selectedCustomer.defaults.salesTaxZip && selectedCustomer.defaults.salesTaxZip.length > 0) {
          return selectedCustomer.defaults.salesTaxZip;
        } else {
          return values.taxZip;
        }
      } else {
        return values.taxZip;
      }
    }
  };

  const formMethods = useForm();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors, isDirty, dirtyFields, isValid },
    setValue,
    trigger,
    getValues,
    setError,
    watch,
    control,
  } = formMethods;

  const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "customerId") {
        let cust = customers.find((c) => c.customerId === value.customerId);
        if (cust) {
          setCustomer(cust);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const reloadTerminals = () => {
    setTerminalLoad(true);
    GetTerminals()
      .then((res) => {
        setTerminals(res.data);
        if (terminal) {
          let newTerminal = res.data.find((pos) => pos.id === terminal.id);
          if (newTerminal) {
            setTerminal(newTerminal);
          } else {
            setTerminal(null);
          }
        }
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setTimeout(() => {
          setTerminalLoad(false);
        }, 700);
      });
  };

  const setCustomer = (cust) => {
    setSelectedCustomer(cust);
    setLoading(true);
    setValue("customerId", cust.customerId);
    setValue("laborDiscount", cust.defaults.laborDiscount);
    setValue("partsMarkup", cust.defaults.partsMarkup);
    setValue("paymentTerms", cust.arData.terms);
    setValue("tax", cust.defaults.tax);
    setValue("taxZip", cust.defaults.salesTaxZip);
    setValues({
      ...values,
      customerId: cust.customerId,
      laborDiscount: cust.defaults.laborDiscount,
      partsMarkup: cust.defaults.partsMarkup,
      paymentTerms: cust.arData.terms,
      tax: cust.defaults.tax,
      taxZip: cust.defaults.salesTaxZip,
    });
    GetOneCustomer(cust.customerId)
      .then((res) => {
        setAddedEquipment([]);
        setAllEquipment(res.data.equipment);
        setTimeout(() => setLoading(false), 700);
        if (cust.notes.length > 0) {
          setNotesModal(true);
        }
      })
      .catch((err) => {
        toast.error("An error has occurred while loading customer details, please try again.");
      });
  };

  const preSetCustomer = (id) => {
    let cust = customers.find((c) => c.customerId === id);
    if (cust) {
      setCustomer(cust);
    }
  };

  const renderEquipmentType = (type) => {
    switch (type) {
      case "generator":
        return "Generator";
      case "pressureWasher":
        return "Pressure Washer";
      case "truck":
        return "Truck";
      case "trailer":
        return "Trailer";
      case "welder":
        return "Welder";
      case "airCompressor":
        return "Air Compressor";
      case "other":
        return "Other";
      default:
        return "Other";
    }
  };

  const filterEquipment =
    query === ""
      ? allEquipment
      : allEquipment.filter((pos) => {
          return (
            pos.equipmentType.toLowerCase().includes(query.toLowerCase().replace(/ /g, "")) ||
            (pos.details && pos.details.make && pos.details.make.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.make && pos.details.model.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.details && pos.details.serialNo && pos.details.serialNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.serialNo && pos.engine.serialNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.specNo && pos.engine.specNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.engine && pos.engine.codeNo && pos.engine.codeNo.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.power && pos.power.toString().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.pwrUnit && pos.pwrUnit.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.fuelType && pos.fuelType.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.location && pos.location.toLowerCase().includes(query.toLowerCase().replace(/ /g, ""))) ||
            (pos.customerEquipId && pos.customerEquipId.toLowerCase().includes(query.toLowerCase().replace(/ /g, "")))
          );
        });

  const addRemoveEquipment = (id) => {
    setDummyLoading(true);
    if (addedEquipment.includes(id)) {
      let tmp = addedEquipment;
      tmp = tmp.filter((eq) => eq !== id);
      setAddedEquipment(tmp);
    } else {
      let tmp = addedEquipment;
      tmp.push(id);
      setAddedEquipment(tmp);
    }
    setTimeout(() => setDummyLoading(false), 500);
  };

  const closeAddEQModal = () => {
    setAddEquipmentModal(false);
    setQuery("");
  };

  const renderAddedEquipment = () => {
    let added = [];
    for (let i = 0; i < addedEquipment.length; i++) {
      let eq = allEquipment.find((e) => e.equipmentId === addedEquipment[i]);
      if (eq) {
        added.push(eq);
      }
    }
    return added.map((eq, i) => (
      <div className="flex flex-row items-center justify-between w-full px-4 py-2 border border-gray-200 rounded-lg">
        <div className="flex flex-col items-start justify-center">
          <p className="font-medium">
            Type: <span className="font-normal">{renderEquipmentType(eq.equipmentType)}</span>
          </p>
          {eq.location && eq.location.length > 0 && (
            <p className="font-medium">
              Location: <span className="font-normal">{eq.location}</span>
            </p>
          )}
          <p className="font-medium">
            {eq.details.make && eq.details.make.length > 0 && (
              <>
                <span className="font-medium">Make: </span>
                <span className="font-normal">{eq.details.make}</span>
              </>
            )}
            {eq.details.model && eq.details.model.length > 0 && (
              <>
                {eq.details.make && eq.details.make.length > 0 && <span className="font-normal"> | </span>}
                <span className="font-medium">Model: </span>
                <span className="font-normal">{eq.details.model}</span>
              </>
            )}
          </p>
          <p className="font-medium">
            Serial No:{" "}
            <span className="font-normal">
              {eq.details?.serialNo?.length
                ? eq.details.serialNo
                : eq.engine?.serialNo?.length
                  ? eq.engine.serialNo
                  : eq.ats?.[0]?.serialNo?.length
                    ? eq.ats[0].serialNo
                    : "Not Provided"}
            </span>
          </p>
          <p className="font-medium">
            Customer Equipment ID: <span className="font-normal">{eq.customerEquipId}</span>
          </p>
          {odometerReadings.find((r) => r.equipmentId === eq.equipmentId) && (
            <p className="font-medium">
              {eq.milesUnit ? eq.milesUnit[0].toUpperCase() + eq.milesUnit.slice(1) : "Miles / Hours"} Reading:{" "}
              <span className="font-normal">{odometerReadings.find((r) => r.equipmentId === eq.equipmentId)?.reading}</span>
            </p>
          )}
        </div>
        <div className="flex items-center justify-end gap-2">
          <SecondaryButton label="Log Miles/Hours" callback={() => openLogOdoModal(eq.equipmentId)} />
          <SecondaryButton label={addedEquipment.includes(eq.equipmentId) ? "Remove" : "Add"} callback={() => addRemoveEquipment(eq.equipmentId)} />
        </div>
      </div>
    ));
  };

  const openLogOdoModal = (id) => {
    setLogOdoId(id);
    setLogOdoModal(true);
  };

  const closeLogOdoModal = () => {
    setLogOdoId(null);
    setLogOdoReading(null);
    setLogOdoModal(false);
  };

  const submitLogOdoReading = () => {
    let tmp = odometerReadings;
    let exists = tmp.findIndex((o) => o.equipmentId === logOdoId);
    if (exists !== -1) {
      tmp[exists].reading = logOdoReading;
    } else {
      tmp.push({ equipmentId: logOdoId, reading: logOdoReading });
    }
    setOdometerReadings(tmp);
    closeLogOdoModal();
    toast("Miles/Hours reading has been logged successfully.");
  };

  const renderLogOdoModal = () => {
    return (
      <Modal
        open={logOdoModal}
        onClose={() => closeLogOdoModal()}
        onOk={() => submitLogOdoReading()}
        title="Log Miles/Hours"
        destroyOnClose
        centered
        width={600}
      >
        <div className="flex flex-col items-start justify-start w-full">
          <label className="pb-2 text-xs text-gray-600 uppercase">Current Miles / Hours Reading</label>
          <InputNumber
            placeholder="Enter the current miles or hours reading"
            name="odometerReading"
            onChange={(v) => setLogOdoReading(v)}
            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 !focus:ring-0 !focus:shadow-none"
            controls={false}
          />
        </div>
      </Modal>
    );
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
    setPaymentAmount(null);
    setPaymentStep(0);
    setSaleId(null);
    setProcessing(false);
    setIntent(false);
    setTerminal(null);
    setTerminalLoad(false);
    setRetryTimeout(false);
    setPaymentError("");
    setEmailReceipt(false);
    setSmsReceipt(false);
    setReceiptInfo({ email: null, phone: null });
    setDummyLoading(true);
    setTimeout(() => setDummyLoading(false), 700);
  };

  const renderPaymentStep = () => {
    if (processing) {
      return (
        <div className="flex items-center flex-col justify-center w-full h-full min-h-[240px] gap-2">
          <Loader size={24} className="text-gray-300 animate-spin" />
          <p className="text-lg font-semibold text-gray-500 animate-pulse">PROCESSING</p>
        </div>
      );
    } else {
      if (paymentStep === 0) {
        // Payment Amount and Method
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <p>Amount to collect</p>
            <InputNumber
              placeholder="Dollar amount to collect"
              step={0.01}
              min={0.5}
              addonBefore="$"
              onChange={(v) => setPaymentAmount(v)}
              className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
              controls={false}
            />
            <div className="flex items-center justify-end w-full gap-2 mt-4 -mb-2">
              <SecondaryButton label="Cancel" callback={() => closePaymentModal()} />
              <PrimaryButton label="Enter Card" callback={() => startCardPayment()} />
              <PrimaryButton label="Terminal Sale" callback={() => startTerminalPayment()} />
            </div>
          </div>
        );
      } else if (paymentStep === 1) {
        // Card Payment - Enter card details
        const stripeOptions = {
          clientSecret: intent.client_secret,
          appearance: {
            theme: "stripe",
          },
        };
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <p>Enter Card Details</p>
            {!processing && (
              <Elements stripe={stripePromise} options={stripeOptions}>
                <StripeForm intent={intent} saleId={saleId} cancelSale={cancelCardSale} nextStep={cardNextStep} />
              </Elements>
            )}
          </div>
        );
      } else if (paymentStep === 2) {
        // Terminal Payment - Select a terminal
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <div className="flex flex-row items-center justify-between w-full px-1 mt-2 text-xs text-left text-gray-500">
              <p>Terminal to use:</p>
              <div
                className="flex flex-row items-center justify-end gap-1 uppercase duration-150 cursor-pointer hover:text-gray-800"
                onClick={() => reloadTerminals()}
              >
                <p>Reload Terminals</p>
                <RefreshDouble className="w-3 h-3" />
              </div>
            </div>
            {!terminalLoad && (
              <Select
                options={terminals.map((t) => ({
                  value: t.id,
                  label: t.label,
                }))}
                onChange={(e) => setTerminal(terminals.find((t) => t.id === e))}
                className="w-full font-sans"
                controls={false}
                placeholder="Select a terminal"
                optionRender={(opt, info) => {
                  return (
                    <div className="flex items-center justify-start gap-2">
                      <p>{opt.label}</p>
                      {terminal && terminal.id === opt.value && <BadgeCheck className="w-4 h-4 text-green-500" />}
                    </div>
                  );
                }}
                defaultValue={terminal ? terminal.id : null}
              />
            )}
            <div className="flex items-center justify-end w-full gap-2 mt-4 -mb-2">
              <SecondaryButton label="Cancel" callback={() => closePaymentModal()} />
              <PrimaryButton label="Next" callback={() => processTerminalPayment()} />
            </div>
          </div>
        );
      } else if (paymentStep === 3) {
        // Terminal Payment - Processing
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Terminal Transaction is Processing...</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Please instruct the customer to complete the sale on the terminal.</p>
              <p className="text-sm text-center text-gray-500">Once the customer is done, capture the payment by clicking on the button below.</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => closePaymentModal()} disabled={processing} label="CANCEL" />
              <SecondaryButton callback={() => retryCapture()} disabled={retryTimeout} label="Retry" />
            </div>
          </div>
        );
      } else if (paymentStep === 4) {
        // Terminal Payment - Payment Success [ Print / Send Receipt ]
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">SUCCESS!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Payment for {formatCurrency(paymentAmount)} has been captured successfully!</p>
              <p className="text-xs text-center text-gray-500">A copy of the receipt will be printed automatically!</p>
              <p className="text-sm font-medium text-center text-black">Would you like to send an e-receipt to the customer as well?</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => setPaymentStep(7)} label="Send E-Receipt" />
              <PrimaryButton callback={() => closePaymentModal()} label="Finish" />
            </div>
          </div>
        );
      } else if (paymentStep === 5) {
        // Terminal Payment - Payment Failed
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Payment Capture Failed!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">
                There was an error capturing the payment. Press the button below to try again or cancel the sale.
              </p>
            </div>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">{paymentError}</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => cancelTerminalSale()} disabled={processing} label="CANCEL SALE" />
              <PrimaryButton callback={() => retryCapture()} label="RETRY" />
            </div>
          </div>
        );
      } else if (paymentStep === 6) {
        // Card Payment - Payment Success [ Print / Send Receipt ]
        return (
          <div className="flex flex-col items-center justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">SUCCESS!</h3>
            <div className="mt-2">
              <p className="text-sm text-center text-gray-500">Payment for {formatCurrency(paymentAmount)} has been captured successfully!</p>
              <p className="text-xs text-center text-gray-500">A copy of the receipt will be printed automatically!</p>
              <p className="text-sm font-medium text-center text-black">Would you like to send an e-receipt to the customer as well?</p>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => setPaymentStep(7)} label="Send E-Receipt" />
              <PrimaryButton callback={() => closePaymentModal()} label="Finish" />
            </div>
          </div>
        );
      } else if (paymentStep === 7) {
        // UNIVERSAL - Send E-Receipt
        return (
          <div className="flex flex-col items-start justify-start w-full gap-2 pt-4">
            <h3 className="text-base font-semibold leading-6 text-center text-gray-900">Send a receipt</h3>
            <div className="flex flex-col items-start justify-center w-full gap-4 mt-2">
              <div className="flex flex-col items-start justify-between w-full gap-2">
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm text-center text-gray-500">Send an Email copy of the receipt</p>
                  <Switch defaultChecked={emailReceipt} onChange={(chk) => setEmailReceipt(chk)} />
                </div>
                <Input
                  placeholder="Customer's Email Address"
                  onChange={(e) => updateReceiptVals("email", e.target.value)}
                  className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                  disabled={!emailReceipt}
                />
              </div>
              <div className="flex flex-col items-start justify-between w-full gap-2">
                <div className="flex items-center justify-between w-full">
                  <p className="text-sm text-center text-gray-500">Send an SMS copy of the receipt</p>
                  <Switch defaultChecked={smsReceipt} onChange={(chk) => setSmsReceipt(chk)} />
                </div>
                <MaskedInput
                  placeholder="Customer's Phone Number"
                  mask={"(000) 000-0000"}
                  className="py-1 text-gray-900 border-gray-300 rounded-md placeholder:text-gray-400 sm:text-sm sm:leading-6 phoneInput"
                  onChange={(e) => updateReceiptVals("phone", e.unmaskedValue)}
                  disabled={!smsReceipt}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-center w-full gap-2 mt-5">
              <SecondaryButton callback={() => closePaymentModal()} label="Finish" />
              <PrimaryButton callback={() => sendEReceipt()} label="Send E-Receipt" />
            </div>
          </div>
        );
      }
    }
  };

  const updateReceiptVals = (field, value) => {
    let tmp = receiptInfo;
    tmp[field] = value;
    setReceiptInfo(tmp);
  };

  const sendEReceipt = async () => {
    let valid = true;
    if (!emailReceipt && !smsReceipt) {
      valid = false;
      toast.error("Please select at least one method to send the receipt.");
    } else if (emailReceipt && (!receiptInfo.email || !validator.isEmail(receiptInfo.email))) {
      valid = false;
      toast.error("Please enter a valid email address to send the receipt.");
    } else if (smsReceipt && (!receiptInfo.phone || !validator.isMobilePhone(receiptInfo.phone))) {
      valid = false;
      toast.error("Please enter a valid phone number to send the receipt.");
    }
    if (valid) {
      let failed = false;
      if (emailReceipt) {
        try {
          await EmailAReceipt(saleId, receiptInfo.email);
        } catch (err) {
          toast.error("Error sending email receipt");
          failed = true;
        }
      }
      if (smsReceipt) {
        try {
          await TextAReceipt(saleId, receiptInfo.phone);
        } catch (err) {
          toast.error("Error sending SMS receipt");
          failed = true;
        }
      }
      if (!failed) {
        toast.success("Receipt has been sent successfully.");
        closePaymentModal();
      }
    }
  };

  const processTerminalPayment = () => {
    if (!terminal || !terminal.id) {
      toast.error("Please select a terminal");
      reloadTerminals();
      return;
    }
    if (terminal.status !== "online") {
      toast.error("Terminal not online, please select another terminal or resolve the issue.");
      reloadTerminals();
      return;
    }
    setProcessing(true);
    StartSale({
      terminal: terminal.id,
      labor: 0,
      reference: `Job Pre-Payment ${dayjs().format("MM/DD/YYYY hh:mm A")}`,
      tax: 0,
      includeTax: false,
      parts: 0,
      gog: 0,
      misc: 0,
      shopFee: 0,
      freight: 0,
      services: paymentAmount,
      processingFees: calculateProcessingFee(paymentAmount),
      total: paymentAmount + calculateProcessingFee(paymentAmount),
      subtotal: paymentAmount,
      manualSale: true,
    })
      .then((res) => {
        setSaleId(res.data.sale);
        let tmp = saleIds;
        tmp.push({ id: res.data.sale, amount: paymentAmount });
        setSaleIds(tmp);
        setRetryTimeout(true);
        setTimeout(() => {
          setRetryTimeout(false);
        }, 15000);
        setPaymentStep(3);
        setTimeout(() => startStatusPing(res.data.sale), 10000);
        setProcessing(false);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while creating a payment intent, please try again.");
        setProcessing(false);
      });
  };

  const cancelTerminalSale = () => {
    setProcessing(true);
    let tmp = saleIds;
    tmp = tmp.filter((s) => s.id !== saleId);
    setSaleIds(tmp);
    AbortTransaction({ terminal: terminal.id, saleId })
      .then((res) => {
        setProcessing(false);
        toast.success("Sale cancelled successfully");
        closePaymentModal();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setProcessing(false);
        closePaymentModal();
      });
  };

  const retryCapture = () => {
    setProcessing(true);
    RetryCapture({ saleId, terminal: terminal.id })
      .then((res) => {
        setSaleId(res.data.sale);
        let tmp = saleIds;
        tmp.push({ id: res.data.sale, amount: paymentAmount });
        setSaleIds(tmp);
        setPaymentStep(3);
        setRetryTimeout(true);
        setPaymentError("");
        setTimeout(() => {
          setRetryTimeout(false);
        }, 15000);
        setTimeout(() => startStatusPing(res.data.sale), 2000);
        setProcessing(false);
      })
      .catch((err) => {
        setPaymentStep(5);
        setProcessing(false);
        toast.error(err.response.data.message);
      });
  };

  const startStatusPing = (sale) => {
    SaleStatus({ saleId: sale })
      .then((res) => {
        if (res.data.status === "processing" || res.data.status === "pending" || res.data.status === "requires_capture") {
          setTimeout(() => {
            setPaymentError("");
            startStatusPing(sale);
          }, 2000);
        } else if (res.data.status === "succeeded") {
          setPaymentStep(4);
          setProcessing(false);
        } else if (res.data.status === "requires_payment_method") {
          if (res.data.paymentIntent.last_payment_error && paymentError === "") {
            toast.error(res.data.paymentIntent.last_payment_error.message);
            setPaymentError(res.data.paymentIntent.last_payment_error.message);
            setPaymentStep(5);
            setProcessing(false);
          } else {
            setTimeout(() => startStatusPing(sale), 2000);
          }
        } else {
          setPaymentStep(3);
          setProcessing(false);
        }
      })
      .catch((err) => {
        setTimeout(() => startStatusPing(sale), 2000);
      });
  };

  const cancelCardSale = () => {
    setProcessing(true);
    let tmp = saleIds;
    tmp = tmp.filter((s) => s.id !== saleId);
    setSaleIds(tmp);
    if (terminal) {
      AbortTransaction({ terminal: terminal.id, saleId: saleId })
        .then((res) => {
          setProcessing(false);
          toast.success("Sale has been cancelled successfully.");
          closePaymentModal();
        })
        .catch((err) => {
          toast.error(err.response.data ? err.response.data.message : "An error has occurred while cancelling the sale, please try again.");
          setProcessing(false);
          closePaymentModal();
        });
    } else {
      setProcessing(false);
      toast.success("Sale has been cancelled successfully.");
      closePaymentModal();
    }
  };

  const cardNextStep = () => {
    setPaymentStep(6);
  };

  const calculateProcessingFee = (amount) => {
    let processingFee = parseFloat((amount * 0.03).toFixed(2));
    return processingFee;
  };

  const startCardPayment = () => {
    if (!paymentAmount || paymentAmount < 0.5) {
      toast.error("Please enter a valid amount to collect.");
      return;
    }
    setProcessing(true);
    CreateManualIntent({
      labor: 0,
      reference: `Job Pre-Payment ${dayjs().format("MM/DD/YYYY hh:mm A")}`,
      tax: 0,
      includeTax: false,
      parts: 0,
      gog: 0,
      misc: 0,
      shopFee: 0,
      freight: 0,
      services: paymentAmount,
      processingFees: calculateProcessingFee(paymentAmount),
      total: paymentAmount + calculateProcessingFee(paymentAmount),
      subtotal: paymentAmount,
      manualSale: true,
    })
      .then((res) => {
        setSaleId(res.data.saleId);
        let tmp = saleIds;
        tmp.push({ id: res.data.saleId, amount: paymentAmount });
        setSaleIds(tmp);
        setIntent(res.data.intent);
        setPaymentStep(1);
        setTimeout(() => setProcessing(false), 500);
      })
      .catch((err) => {
        toast.error(err.response.data ? err.response.data.message : "An error has occurred while creating a payment intent, please try again.");
        setProcessing(false);
      });
  };

  const startTerminalPayment = () => {
    if (!paymentAmount || paymentAmount < 0.5) {
      toast.error("Please enter a valid amount to collect.");
      return;
    }
    setPaymentStep(2);
  };

  const renderSale = (data, index) => {
    return (
      <div className="flex flex-row items-center justify-between w-full px-4 py-3 rounded-lg bg-green-100/60">
        <div className="flex items-center justify-start gap-2">
          <p className="text-sm font-medium text-green-700">Payment #{index + 1}</p>
          <p className="text-xs font-medium text-green-800 opacity-60">| {data.id}</p>
        </div>
        <p className="text-sm font-medium text-green-700">Amount: {formatCurrency(data.amount)}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-full">
      <div className="flex flex-col items-center justify-start flex-grow w-full px-10 pb-3 overflow-y-auto">
        {!loading ? (
          <>
            <Helmet>
              <title>New Job | HTPS ERP</title>
            </Helmet>
            <div className="flex flex-row items-center justify-between w-full py-3 mb-5 border-b border-gray-300">
              <div className="flex flex-col items-start justify-center gap-1">
                <p className="font-semibold uppercase">New Job</p>
                {nextJobNo && <p className="text-xs text-gray-500">Tentative Job No: {nextJobNo}</p>}
              </div>
              <div className="flex items-center justify-end gap-2">
                {values.customerId && values.customerId.length > 5 && (
                  <SecondaryButton label="Open Customer" callback={() => window.open(`${host}/customers/${values.customerId}`, "_blank")} />
                )}
                <SecondaryButton label="Collect Payment" callback={() => setPaymentModal(true)} />
                <SecondaryButton label="Cancel" callback={() => navigate("/jobs")} />
              </div>
            </div>
            {/* <FormController onSubmit={submitForm} fields={newJobForm} values={values} buttonText={"Create Job"} fullWidth={true} /> */}
            <form onSubmit={handleSubmit(submitForm)} className={`w-full flex flex-row justify-between items-center gap-5`} key="upperForm">
              <FormProvider {...formMethods}>
                <div className="flex flex-col items-center justify-start w-full gap-2">
                  <div key="customerId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="customerId" className="pb-1 text-xs text-gray-600 uppercase">
                      Customer
                    </label>
                    <Controller
                      control={control}
                      name="customerId"
                      rules={{
                        required: "Customer is required",
                        validate: (value) => value.length >= 3 || "Please select a customer",
                      }}
                      defaultValue={values.customerId.length > 0 ? values.customerId : null}
                      render={(props) => (
                        <Select
                          placeholder={"Select a customer"}
                          ref={props.field.ref}
                          name="customerId"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e);
                            preSetCustomer(e);
                          }}
                          options={customers.map((c) => ({
                            value: c.customerId,
                            label: `${c.customerCode}${c.company.length > 0 ? " | " + c.company : ""}`,
                          }))}
                          defaultValue={values.customerId && values.customerId.length > 0 ? values.customerId : null}
                          {...props}
                          className="w-full font-sans"
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="customerId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="locationId" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="locationId" className="pb-1 text-xs text-gray-600 uppercase">
                      Shop / Job Location
                    </label>
                    <Controller
                      control={control}
                      name="locationId"
                      rules={{
                        required: "Location is required",
                        validate: (value) => value.length >= 3 || "Please select a location",
                      }}
                      defaultValue={values.locationId.length > 0 ? values.locationId : null}
                      render={(props) => (
                        <Select
                          placeholder={"Select a location"}
                          ref={props.field.ref}
                          name="locationId"
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={locations}
                          defaultValue={values.locationId && values.locationId.length > 0 ? values.locationId : null}
                          {...props}
                          className="w-full font-sans"
                          controls={false}
                          showSearch
                          filterOption={filterOption}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="locationId" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="description" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="description" className="pb-1 text-xs text-gray-600 uppercase">
                      Job Description
                    </label>
                    <Controller
                      control={control}
                      name="description"
                      rules={{
                        required: "Job description is required",
                        validate: (value) => value.length >= 3 || "Please provide a job description",
                      }}
                      defaultValue={values.description.length > 0 ? values.description : null}
                      render={(props) => (
                        <Input
                          placeholder="Briefly describe the job"
                          ref={props.field.ref}
                          name="description"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e.target.value);
                          }}
                          defaultValue={values.description && values.description.length > 0 ? values.description : null}
                          {...props}
                          className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="description" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="details" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="details" className="pb-1 text-xs text-gray-600 uppercase">
                      Job Details
                    </label>
                    <Controller
                      control={control}
                      name="details"
                      rules={{
                        required: false,
                      }}
                      defaultValue={values.details.length > 0 ? values.details : null}
                      render={(props) => (
                        <Input.TextArea
                          placeholder="Provide any additional details about the job"
                          ref={props.field.ref}
                          name="details"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e.target.value);
                          }}
                          rows={4}
                          defaultValue={values.details && values.details.length > 0 ? values.details : null}
                          {...props}
                          autoSize={false}
                          className="w-full !resize-none font-sans py-2 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="details" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="eoNotes" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="eoNotes" className="pb-1 text-xs text-gray-600 uppercase">
                      Employee-Only Notes
                    </label>
                    <Controller
                      control={control}
                      name="eoNotes"
                      rules={{
                        required: false,
                      }}
                      defaultValue={values.eoNotes.length > 0 ? values.eoNotes : null}
                      render={(props) => (
                        <Input
                          placeholder="Enter any notes for employees"
                          ref={props.field.ref}
                          name="eoNotes"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e.target.value);
                          }}
                          defaultValue={values.eoNotes && values.eoNotes.length > 0 ? values.eoNotes : null}
                          {...props}
                          className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="eoNotes" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="clientPo" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="clientPo" className="pb-1 text-xs text-gray-600 uppercase">
                      Customer's PO Number
                    </label>
                    <Controller
                      control={control}
                      name="clientPo"
                      rules={{
                        required: false,
                      }}
                      defaultValue={values.clientPo.length > 0 ? values.clientPo : null}
                      render={(props) => (
                        <Input
                          placeholder="Enter the customer's PO Number if applicable"
                          ref={props.field.ref}
                          name="clientPo"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e.target.value);
                          }}
                          defaultValue={values.clientPo && values.clientPo.length > 0 ? values.clientPo : null}
                          {...props}
                          className="block w-full px-4 py-2 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-0"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="clientPo" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  {authState.user.functionCategory !== "technician" && (
                    <div key="laborDiscount" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="laborDiscount" className="pb-1 text-xs text-gray-600 uppercase">
                        Labor Discount
                      </label>
                      <Controller
                        control={control}
                        name="laborDiscount"
                        rules={{
                          required: "Labor discount is required",
                          validate: (value) => validator.isInt(value.toString()) || "Please enter a labor discount",
                        }}
                        defaultValue={selectedCustomer ? selectedCustomer.defaults.laborDiscount : null}
                        render={(props) => (
                          <InputNumber
                            placeholder="Labor Discount"
                            ref={props.field.ref}
                            name="laborDiscount"
                            onBlur={props.field.onBlur}
                            onChange={(e) => {
                              props.field.onChange(e);
                            }}
                            defaultValue={selectedCustomer ? selectedCustomer.defaults.laborDiscount : null}
                            {...props}
                            value={props.field.value}
                            controls={false}
                            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="laborDiscount" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  )}
                  {authState.user.functionCategory !== "technician" && (
                    <div key="partsMarkup" className="flex flex-col items-start justify-start w-full">
                      <label htmlFor="partsMarkup" className="pb-1 text-xs text-gray-600 uppercase">
                        Parts Markup
                      </label>
                      <Controller
                        control={control}
                        name="partsMarkup"
                        rules={{
                          required: "Parts markup is required",
                          validate: (value) => validator.isInt(value.toString()) || "Please enter a valid parts markup",
                        }}
                        defaultValue={selectedCustomer ? selectedCustomer.defaults.partsMarkup : null}
                        render={(props) => (
                          <InputNumber
                            placeholder="Parts Markup"
                            ref={props.field.ref}
                            name="partsMarkup"
                            onBlur={props.field.onBlur}
                            onChange={(e) => {
                              props.field.onChange(e);
                            }}
                            {...props}
                            defaultValue={selectedCustomer ? selectedCustomer.defaults.partsMarkup : null}
                            value={props.field.value}
                            controls={false}
                            className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                          />
                        )}
                      />
                      <ErrorMessage errors={errors} name="partsMarkup" as="p" className="px-1 pt-1 text-xs text-red-500" />
                    </div>
                  )}
                  <div key="paymentTerms" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="paymentTerms" className="pb-1 text-xs text-gray-600 uppercase">
                      Payment Terms
                    </label>
                    <Controller
                      control={control}
                      name="paymentTerms"
                      rules={{
                        required: "Customer's payment terms are required",
                        validate: (value) => value.length > 2 || "Please select payment terms for the customer",
                      }}
                      defaultValue={selectedCustomer ? selectedCustomer.arData.terms : null}
                      render={(props) => (
                        <Select
                          placeholder="Select Payment Terms"
                          ref={props.field.ref}
                          name="paymentTerms"
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={paymentTerms}
                          defaultValue={selectedCustomer ? selectedCustomer.arData.terms : null}
                          value={props.field.value}
                          {...props}
                          className="w-full font-sans"
                          controls={false}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="paymentTerms" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="tax" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="tax" className="pb-1 text-xs text-gray-600 uppercase">
                      What's the customer's tax status?
                    </label>
                    <Controller
                      control={control}
                      name="tax"
                      rules={{
                        required: "Customer's tax status is required",
                        validate: (value) => value.length >= 3 || "Please select a tax status",
                      }}
                      defaultValue={selectedCustomer ? selectedCustomer.defaults.tax : null}
                      render={(props) => (
                        <Select
                          placeholder="Select Payment Terms"
                          ref={props.field.ref}
                          name="tax"
                          onBlur={props.field.onBlur}
                          onChange={props.field.onChange}
                          options={taxStatus}
                          defaultValue={selectedCustomer ? selectedCustomer.defaults.tax : null}
                          value={props.field.value}
                          {...props}
                          className="w-full font-sans"
                          controls={false}
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="tax" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div key="taxZip" className="flex flex-col items-start justify-start w-full">
                    <label htmlFor="taxZip" className="pb-1 text-xs text-gray-600 uppercase">
                      Job ZIP Code
                    </label>
                    <Controller
                      control={control}
                      name="taxZip"
                      rules={{
                        required: "ZIP code is required",
                        validate: (value) => testZip(value) || "Please enter a valid ZIP code",
                      }}
                      defaultValue={selectedCustomer ? selectedCustomer.defaults.salesTaxZip : null}
                      render={(props) => (
                        <InputNumber
                          placeholder="Job ZIP Code"
                          ref={props.field.ref}
                          name="taxZip"
                          onBlur={props.field.onBlur}
                          onChange={(e) => {
                            props.field.onChange(e);
                          }}
                          {...props}
                          defaultValue={getTaxDefaultValue()}
                          value={props.field.value}
                          controls={false}
                          className="w-full font-sans py-0.5 px-1.5 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-0"
                        />
                      )}
                    />
                    <ErrorMessage errors={errors} name="taxZip" as="p" className="px-1 pt-1 text-xs text-red-500" />
                  </div>
                  <div className="flex flex-row items-center justify-end w-full gap-3 py-5 my-2 border-t border-gray-300">
                    {allEquipment.length > 0 && <SecondaryButton label="Add Equipment to this job" callback={() => setAddEquipmentModal(true)} />}
                    <button
                      type="submit"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-5 py-2 text-xs font-semibold uppercase text-white shadow-sm hover:bg-blue-500"
                    >
                      {isSubmitting ? "Processing..." : "Create Job"}
                    </button>
                  </div>
                </div>
              </FormProvider>
            </form>
            <div className="flex flex-col items-start justify-start w-full gap-2">
              {addedEquipment.length > 0 && <p className="text-lg font-semibold">Added Equipment</p>}
              {renderAddedEquipment()}
              {renderLogOdoModal()}
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-2 py-3">{saleIds.map((sid, index) => renderSale(sid, index))}</div>
            <Modal
              open={notesModal}
              onCancel={() => setNotesModal(false)}
              onOk={() => setNotesModal(false)}
              title="Customer Notes"
              footer={[<PrimaryButton label="Close" callback={() => setNotesModal(false)} />]}
              centered
            >
              <div className="w-full flex flex-col justify-start items-center gap-4 h-full max-h-[600px] py-5 overflow-y-auto">
                {selectedCustomer &&
                  selectedCustomer.notes.map((note, i) => (
                    <div className="flex flex-col items-start justify-center w-full px-3 py-2 rounded-lg bg-gray-50">
                      <h3 className="w-full text-sm font-base text-slate-800">{note.note}</h3>
                      <div className="flex flex-row items-center justify-between w-full mt-2">
                        <p className="text-xs text-slate-400/75 font-medium pt-0.5">{employees.find((emp) => emp.employeeId === note.createdBy).name}</p>
                        <p className="text-xs text-slate-400/75 font-medium pt-0.5">{dayjs(note.createdAt).format("MM/DD/YYYY")}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </Modal>
            <Modal
              open={addEquipmentModal}
              onCancel={() => closeAddEQModal()}
              onOk={() => closeAddEQModal()}
              title="Add Equipment to Job"
              footer={[<PrimaryButton label="Close" callback={() => closeAddEQModal()} />]}
              centered
              width={800}
              destroyOnClose
            >
              <div className="flex items-center justify-end w-full gap-2 mb-3">
                <Input
                  placeholder="Search Equipment"
                  className="w-full max-w-[350px] px-4 py-1.5 font-sans text-sm border-gray-200 rounded-md focus:border-blue-500 hover:border-gray-400 focus:ring-0"
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className="w-full flex flex-col justify-start items-center gap-2 overflow-y-auto max-h-[60vh] px-1">
                {filterEquipment.map((eq, i) => (
                  <div className="flex flex-row items-center justify-between w-full px-4 py-2 border border-gray-200 rounded-lg">
                    <div className="flex flex-col items-start justify-center">
                      <p className="font-medium">
                        Type: <span className="font-normal">{renderEquipmentType(eq.equipmentType)}</span>
                      </p>
                      {eq.location && eq.location.length > 0 && (
                        <p className="font-medium">
                          Location: <span className="font-normal">{eq.location}</span>
                        </p>
                      )}
                      <p className="font-medium">
                        Make: <span className="font-normal">{eq.details.make}</span>{" "}
                        {eq.details.model && eq.details.model.length > 0 && (
                          <>
                            <span className="font-normal"> | </span>
                            <span className="font-medium">Model: </span>
                            <span className="font-normal">{eq.details.model}</span>
                          </>
                        )}
                      </p>
                      {eq.details && eq.details.serialNo && (
                        <p className="font-medium">
                          Serial No: <span className="font-normal">{eq.details.serialNo}</span>
                        </p>
                      )}
                      <p className="font-medium">
                        Customer Equipment ID: <span className="font-normal">{eq.customerEquipId}</span>
                      </p>
                    </div>
                    <SecondaryButton label={addedEquipment.includes(eq.equipmentId) ? "Remove" : "Add"} callback={() => addRemoveEquipment(eq.equipmentId)} />
                  </div>
                ))}
              </div>
            </Modal>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      <Modal open={paymentModal} destroyOnClose onCancel={() => closePaymentModal()} title="Collect Payment" centered width={800} footer={[]}>
        {renderPaymentStep()}
      </Modal>
    </div>
  );
};

const StripeForm = ({ intent, saleId, cancelSale, nextStep }) => {
  const [processing, setProcessing] = useState(false);
  const [nameOnCard, setNameOnCard] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const saleCancel = () => {
    cancelSale();
  };

  const submitCard = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe not initialized");
      return;
    }
    if (nameOnCard === "") {
      toast.error("Please enter the name on the card");
      return;
    }
    let cardElement = elements.getElement(CardElement);
    setProcessing(true);
    stripe
      .confirmCardPayment(intent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      })
      .then(function (result) {
        if (result.error) {
          setProcessing(false);
          toast.error(result.error.message);
        } else {
          let piId = result.paymentIntent.id;
          CheckCardSale({ saleId: saleId, paymentIntent: piId })
            .then((res) => {
              toast.success("Payment Successful");
              nextStep(res.data.location);
            })
            .catch((err) => {
              toast.error(err.response.data.message);
              setProcessing(false);
            });
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setProcessing(false);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full">
      <div className="w-2/3 mx-auto my-2">
        <label htmlFor="nameOnCard" className="block w-full ml-1 text-xs text-gray-500">
          Name on Card
        </label>
        <div className="mt-1">
          <input
            type="text"
            name="nameOnCard"
            id="nameOnCard"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-400 sm:text-sm sm:leading-6"
            placeholder="John Doe"
            aria-describedby="Name on Card"
            onChange={(e) => setNameOnCard(e.target.value)}
          />
        </div>
      </div>
      <div className="w-2/3 mx-auto mt-2">
        <label htmlFor="CardElement" className="block w-full ml-1 text-xs text-gray-500">
          Card Information
        </label>
      </div>
      <div className="w-2/3 p-2 mx-auto mt-1 border border-gray-300 rounded-md">
        <CardElement
          className="card"
          options={{
            showIcon: true,
            style: {
              base: {
                backgroundColor: "white",
              },
              paddingTop: "5px",
            },
            disableLink: true,
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between w-2/3 gap-2 mx-auto mt-5 sm:mt-6">
        <SecondaryButton callback={() => saleCancel()} disabled={processing} label="CANCEL SALE" />
        <SecondaryButton callback={() => submitCard()} disabled={processing} label={processing ? "PROCESSING" : "SUBMIT"} />
      </div>
    </div>
  );
};

export default NewJob;
